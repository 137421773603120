import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {Button, Tooltip} from "@material-ui/core";
import { CustomDialog } from "~components";
import { useCompany } from "~store";

const ActionsColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <div>
      <Tooltip arrow title={"Edit"}>
        <NavLink to={`/companies/company-editor/${row.id}`}>
          <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={"/media/svg/icons/Communication/Write.svg"} />
            </span>
          </span>
        </NavLink>
      </Tooltip>
      <DeleteButton row={row} />
    </div>
  );
}

const DeleteButton = ({ row }) => {

  const [, actions] = useCompany();
  const [triggerClose, setTriggerClose] = useState(false);
  const [message, setMessage] = useState("");

  const deleteCompany = async (id) => {
    setMessage("Deleting ...");
    await actions.deleteCompany(id);
    setMessage("Successfully deleted.");

    setTimeout(
      function() {
        setTriggerClose(true);
      }, 1000
    );
    setTimeout(
    function() {
      setTriggerClose(false);
      }, 1500
    );
  }

  return(
    <CustomDialog
      title={""}
      dividers={false}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Delete"}>
          <button className="btn btn-icon btn-light btn-hover-danger btn-sm">
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={"/media/svg/icons/General/Trash.svg"} />
            </span>
          </button>
        </Tooltip>
      }
      actionButtons={
        <Button
          className={"btn btn-danger btn-elevate"}
          onClick={() => deleteCompany(row.id)}
        >
          Yes
        </Button>
      }
    >
      <div className={"font-size-h3 w-100 text-center py-10"}>
        {!message && (
          <>
            Are you sure you want to delete this company (<span className={"font-weight-bold"}>
            { row.name }
            </span>)?
          </>
        )}
        {message && message}
      </div>
    </CustomDialog>
  )
}

export default ActionsColumnFormatter;
