import React from "react";
import PropTypes from 'prop-types';

const FormSuccessMessage = ({ message }) => {
  return(
    <div className="alert alert-custom alert-light-success fade show mb-2 px-6 p-2" role="alert">
      <div className="alert-icon align-items-unset">
        <i className="flaticon2-check-mark icon-lg" />
      </div>
      <div className="alert-text">
        <span className={"font-weight-bolder font-size-h6 pr-2"}>
          Success!
        </span>
        {message}
      </div>
    </div>
  )
}

FormSuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FormSuccessMessage;
