import React from 'react';
import globalHook from 'use-global-hook';

const initCompany = {
  id: "",
  recruiter_uuid: "",
  name: "",
  tagline: "",
  location: "",
  description: "",
  logo: "",
  remove_logo: "",
  banner: "",
  remove_banner: "",
  banner_preview: "",
  logo_preview: "",
};

const state = {
  allCompanies: [],
  companies: [],
  company: JSON.parse(JSON.stringify(initCompany)),
  listCount: 0,
  loading: false,
  lastParams: '',
  errors: "",
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetCompany: (store) => {
    store.setState({company: JSON.parse(JSON.stringify(initCompany))});
  },
  fetchCompany: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/company/${id}`
      });

      let newCompany = store.state.company;

      for(let key of Object.keys(newCompany)) {
        newCompany[key] = data.data.hasOwnProperty(key) && data.data[key] !== null ? data.data[key] : newCompany[key];
      }
      newCompany.logo_preview = newCompany.logo;
      newCompany.banner_preview = newCompany.banner;

      store.setState({ company: newCompany});

      return data.data;
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchCompanies: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });
    try {
      let params = "";

      if(queryParams && filters) {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField.replace(".", "->")}
          &sortOrder=${queryParams.sortOrder}
          &searchText=${filters.searchText}`;
      } else {
        params = store.state.lastParams;
      }

      store.setState({lastParams : params });

      let { data } = await global.axios({
        method: "GET",
        url: `/api/company${params}`
      });
      store.setState({
        companies: data.data,
        listCount: data.meta.total,
        loading: false,
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  fetchAll: async (store, recruiterUuid = "") => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-companies?recruiter_uuid=${recruiterUuid}`
      });

      let companies = data.data;
      store.setState({ allCompanies: companies });

      return companies;
    }
    catch (error) {
      console.log(error);
    }
  },
  saveCompany: async (store) => {

    let user = localStorage.user ? JSON.parse(localStorage.user) : {};
    let company = store.state.company;

    if(!user.roles.includes('admin')) {
      company.recruiter_uuid = user.recruiter_uuid;
    }

    try {
      let config = {headers: { "Content-Type": "multipart/form-data" }};
      let url = `/api/company`;

      let formData = new FormData();
      for(let key of Object.keys(company)) {
        formData.append(key, company[key]);
      }

      if(company.id) {
        url += `/${company.id}`;
        formData.append("_method", "PUT");
      }

      let { data } = await global.axios.post(url, formData, config);

      store.actions.fetchCompanies();

      return data.data;
    }
    catch ({ response }) {
      console.log("error", response);
      store.setState({ errors: response.data.errors});
      return false;
    }
  },
  deleteCompany: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/company/${id}`
      });
      store.actions.fetchCompanies();
    }
    catch (error) {
      console.log(error);
    }
  }
};

const useCompany = globalHook(React, state, actions);

export default useCompany;
