import React, {memo} from "react";

const TextFieldForm = ({
  label = "",
  name = "",
  type = "text",
  className = "",
  wrapperClassName = "mb-5",
  ...props
}) => {
  return(
    <div className={`form-group ${wrapperClassName}`}>
      {label && (
        <label className={`mb-1 pl-1 font-weight-bolder`}>
          {label}
        </label>
      )}
      <input
        name={name}
        type={type}
        className={`form-control rounded-lg border-0 ${className}`}
        {...props}
      />
    </div>
  )
}

export default memo(TextFieldForm);
