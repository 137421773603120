import React from "react";
import UserEditModal from "../UserEditModal";
import UserDeleteModal from "../UserDeleteModal";

const ActionsColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <div>
      <UserEditModal row={row} />
      <UserDeleteModal row={row} />
    </div>
  );
}

export default ActionsColumnFormatter;
