import React, {memo, useEffect, useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .ck.ck-reset_all, .ck.ck-reset_all *": {
      color: "#7e82a6!important",
    },
    "& .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners": {
      background: "#f1f0f0 !important",
      padding: "0 15px",
      borderRadius: "12px !important",
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
      borderColor: "#f1f0f0 !important",
    },
    "& .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners": {
      borderRadius: "12px !important",
      borderTopLeftRadius: "0 !important",
      borderTopRightRadius: "0 !important",
      borderColor: "#f1f0f0 !important",
      minHeight: 90,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

const editorConfiguration = {
  toolbar: ['Bold', 'Italic', 'Bulletedlist', 'numberedList', 'wproofreader'],
  wproofreader: {
    serviceId: '0K9LpHdPhb3NktN',
    srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
    autoStartup: true,
    enableGrammar: true,
    lang: 'en_US',
    enableBadgeButton: true,
    actionItems: ['addWord', 'ignoreAll', 'settings', 'toggle', 'proofreadDialog'],
    globalProofreadDialog: true,
    settingsSections: ['dictionaries', 'languages', 'options'],
    onErrorRequest: function (err) {
      console.log('err', err)
    },
    onLoad: function (data) {
      // console.log('load', data)
    }
  }
};

const CustomCKEditor = ({
  data = "",
  handleOnBlur = false,
  handleOnChange = false
}) => {

  const classes = useStyles();
  const [text, setText] = useState(data);
  const [delayDebounceFn, setDelayDebounceFn] = useState(0);

  useEffect(() => {
    if(data !== text) {
      setText(data);
    }
  }, [data])

  const handleChange = (newText) => {
    clearTimeout(delayDebounceFn);
    setText(newText);

    setDelayDebounceFn(
      setTimeout(() => {
        handleOnChange(newText)
      }, 1000)
    );
  }

  return(
    <div className={classes.root}>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        data={text}
        onBlur={(event, editor) => {
          if(handleOnBlur) {
            handleOnBlur(editor.getData());
          }
        }}
        onChange={(event, editor) => {
          if(handleOnChange) {
            handleChange(editor.getData());
          }
        }}
      />
    </div>
  )
}

export default memo(CustomCKEditor);
