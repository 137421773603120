import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  token: '',
  user: {},
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  isLoggedIn: () => {
    return localStorage.hasOwnProperty('token')
      && localStorage.token !== ""
      && localStorage.hasOwnProperty('user')
      && localStorage.user !== "";
  },
  login: async (store, postData) => {
    try {
      let { data } = await global.axios({
        method: "POST",
        url: `/api/user/login`,
        data: postData
      });

      localStorage.setItem('token', data.data.token);
      localStorage.setItem('user', JSON.stringify(data.data.user));

      store.setState({
        token: data.data.token,
        user: data.data.user
      });

      return true;
    } catch (err) {
      return false;
    }
  },
  logout: (store, history) => {

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    store.setState({
      token: "",
      user: {}
    });

    history.push('/login')
  },
  setUserDetails: async (store) => {
    await store.setState({
      token: localStorage.getItem("token"),
      user: JSON.parse(localStorage.getItem("user"))
    });
  },
  updateUserDetails: (store, newUser) => {
    store.setState({ user: newUser })
    localStorage.setItem("user", JSON.stringify(newUser));
  },
  getUserRole: (store) => {
    let role = store.state.user.roles[0]['name'];
    role = role === "admin" ? "administration" : role;

    return role.charAt(0).toUpperCase() + role.slice(1);
  },
};

const useAuth = globalHook(React, state, actions);

export default useAuth;
