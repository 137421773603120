import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  CompanyColumnFormatter,
  DateSubmittedColumnFormatter,
  NameColumnFormatter,
  PositionColumnFormatter,
  RecruiterColumnFormatter
} from "~pages/Applications/components/column-formatters";
import globalHook from 'use-global-hook';

let recruiterColumn =  {
  dataField: "recruiter",
  text: "Recruiter",
  formatter: RecruiterColumnFormatter,
  sort: true,
  sortCaret: sortCaret,
  headerSortingClasses,
  headerClasses: ""
};

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "data.last-name",
    text: "Name",
    sort: true,
    formatter: NameColumnFormatter,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "position",
    text: "Position",
    formatter: PositionColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "company",
    text: "Company",
    formatter: CompanyColumnFormatter,
    sort: false,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "",
    text: "Date Submitted",
    formatter: DateSubmittedColumnFormatter,
    sort: false,
    headerClasses: "text-center",
    classes: "text-center",
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3 align-baseline",
    style: { minWidth: "125px" },
  },
];

const getColumns = () => {
  let tableColumns = columns;
  if(localStorage.user && JSON.parse(localStorage.user).roles.includes('admin')) {
    tableColumns = [];
    for(let i in columns) {
      tableColumns.push(columns[i]);
      if(i == 3) {
        tableColumns.push(recruiterColumn);
      }
    }
  }
  return tableColumns;
}

const initialState = {
  columns: getColumns(),
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
    company: "",
    position: "",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
};

const useApplicationList = globalHook(React, state, actions);

export default useApplicationList;
