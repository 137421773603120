import React from "react";

const PositionColumnFormatter = (cellContent, row) => {
  return (
    <div>
      {row.job_listings.length > 0 ? row.job_listings[0].position : ""}
    </div>
  );
}

export default PositionColumnFormatter;
