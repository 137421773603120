import React from 'react';
import globalHook from 'use-global-hook';

const state = {
  roles: [],
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchRoles: async (store) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/role`
      });

      store.setState({ roles: data.data });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
};

const useRole = globalHook(React, state, actions);

export default useRole;
