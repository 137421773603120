import React from "react";

const RecruiterColumnFormatter = (cellContent, row) => {  
  return (
    <div>
      {row.job_listings.length > 0 && row.job_listings[0].company ? row.job_listings[0].company.recruiter.name : ""}
    </div>
  );
}

export default RecruiterColumnFormatter;