import React from 'react';
import globalHook from 'use-global-hook';
import { format } from "date-fns";

const initialState = {
  companies_count: 0,
  jobs_count: 0,
  applicants_count: null,
  company_chart: null,
  job_chart: null,
  applicant_chart: 0,
  applicants: [],
  jobs: [],
  dateFilter: {
    startDate: new Date(`01/01/${(new Date()).getFullYear()}`),
    endDate: new Date(`12/31/${(new Date()).getFullYear()}`),
    key: 'selection'
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  getQueryParams: (store) => {
    let dateStart = format(store.state.dateFilter.startDate, 'yyyy-MM-dd');
    let dateEnd = format(store.state.dateFilter.endDate, 'yyyy-MM-dd');

    let params = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return params;
  },
  fetchData: async (store) => {
    try {
      let params = store.actions.getQueryParams();
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-dashboard-data${params}`
      });
      store.setState(data);
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useDashboard = globalHook(React, state, actions);

export default useDashboard;
