import React, { lazy } from 'react';
import globalHook from 'use-global-hook';

const Login = lazy(() => import("~pages/Auth/Login"));
const Registration = lazy(() => import("~pages/Auth/Registration"));
const ForgotPassword = lazy(() => import("~pages/Auth/ForgotPassword"));

const Dashboard = lazy(() => import("~pages/Dashboard"));

const PersonaInformation = lazy(() => import("~pages/MyProfile/PersonaInformation"));
const AccountInformation = lazy(() => import("~pages/MyProfile/AccountInformation"));
const ChangePassword = lazy(() => import("~pages/MyProfile/ChangePassword"));

const UserManagement = lazy(() => import("~pages/UserManagement"));

const ApplicationForm = lazy(() => import("~pages/ApplicationForm"));
const ApplicationThankYouPage = lazy(() => import("~pages/ApplicationForm/components/ThankYouPage"));
const ApplicationPdf = lazy(() => import("~pages/ApplicationPdf"));
const Applications = lazy(() => import("~pages/Applications"));
const ApplicationEditor = lazy(() => import("~pages/ApplicationEditor"));
const ApplicationFormEditor = lazy(() => import("~pages/ApplicationEditor/Editor"));
const ApplicationPdfViewer = lazy(() => import("~pages/ApplicationEditor/PDFViewer"));

const ApplicantComments = lazy(() => import("~pages/Applicant/Comments"));
const ApplicantViewPdf = lazy(() => import("~pages/Applicant/ViewPdf"));

const Companies = lazy(() => import("~pages/Companies"));
const CompanyEditor = lazy(() => import("~pages/CompanyEditor"));

const Jobs = lazy(() => import("~pages/Jobs"));
const JobInformation = lazy(() => import("~pages/JobInformation"));

const state = {
  routes: [
    { path: "/login", component: Login, exact: true, private: false },
    { path: "/registration", component: Registration, exact: true, private: false },
    { path: "/forgot-password", component: ForgotPassword, exact: true, private: false },

    { path: "/my-profile/personal-information", component: PersonaInformation, exact: true, private: true },
    { path: "/my-profile/account-information", component: AccountInformation, exact: true, private: true },
    { path: "/my-profile/change-password", component: ChangePassword, exact: true, private: true },

    { path: "/", redirect: "/dashboard", exact: true, private: true },
    { path: "/dashboard", component: Dashboard, exact: true, private: true },

    { path: "/user-management", component: UserManagement, exact: true, private: true },

    { path: "/application-form", component: ApplicationForm, exact: true, private: false },
    { path: "/application-pdf/:id", component: ApplicationPdf, exact: true, private: false },
    { path: "/application-success", component: ApplicationThankYouPage, exact: true, private: false },
    
    { path: "/applications", component: Applications, exact: true, private: true },
    { path: "/applications/application-editor/:id", component: ApplicationEditor, exact: true, private: true },
    { path: "/applications/application-form-editor/:id", component: ApplicationFormEditor, exact: true, private: true },
    { path: "/applications/application-pdf/:id", component: ApplicationPdfViewer, exact: true, private: true },

    { path: "/applicant-form-comments/:id", component: ApplicantComments, exact: true, private: false },
    { path: "/applicant-form-pdf/:id", component: ApplicantViewPdf, exact: true, private: false },

    { path: "/companies", component: Companies, exact: true, private: true },
    { path: "/companies/company-editor/:id", component: CompanyEditor, exact: true, private: true },

    { path: "/jobs", component: Jobs, exact: true, private: true },
    { path: "/job-information/:id", component: JobInformation, exact: true, private: true },
  ]
};

const actions = {
  isPrivate: (store, path) => {
    let res = store.state.routes.filter(route => route.path === path && route.private);
    return res.length > 0;
  },
  isFound: (store, path) => {
    let res = store.state.routes.filter(route => route.path === path);
    return res.length > 0;
  },
};

const useRoute = globalHook(React, state, actions);

export default useRoute;
