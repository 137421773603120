import React from 'react';
import globalHook from 'use-global-hook';

const state = {
  loading: false,
  commentResponse: {},
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchCommentResponse: async (store, comment_id) => {
    store.setState({ loading: true });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-comment-response?comment_id=${comment_id}`
      });

      let responseData = Array.isArray(data.data) && data.data.length === 0 ? null : data.data;
      if(responseData) {
        responseData.id = data.id;
        responseData.approved_at = data.approved_at;
        store.setState({
          commentResponse: data.data,
          loading: false,
        });
        return responseData;
      }
      return false;
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
      return {};
    }
  },
  saveCommentResponse: async (store, comment_response) => {
    try {
      let config = {headers: { "Content-Type": "application/json" }};
      let url = `/api/comment-response`;

      await global.axios.post(url, comment_response, config);
      await store.actions.fetchComments(comment_response.comment_id);

      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  approveResponse: async (store, payload) => {
    try {
      payload["_method"] = "PUT";
      await global.axios({
        method: "POST",
        data: payload,
        url: `/api/approve-response/${payload.comment_response_id}`
      });
      return true;
    }
    catch({ response }) {
      console.log(response);
    }
  }
};

const useCommentResponse = globalHook(React, state, actions);

export default useCommentResponse;
