import React from "react";
import {Avatar} from "@material-ui/core";

const LogoColumnFormatter = (cellContent, row) => {
  return (
    <div className={`w-100 d-flex flex-center`}>
      <Avatar
        alt="company logo"
        src={row.logo === "" ? "/media/logos/blank.jpg" : row.logo}
        variant={"square"}
      />
    </div>
  );
}

export default LogoColumnFormatter;
