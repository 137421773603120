import React from "react";

const TextFieldRow = ({
  label,
  name,
  value,
  type = "text",
  className = "",
  helperText = "",
  ...props
}) => {
  return(
    <div className="form-group">
      <label className={`pl-1`}>
        {label}
      </label>
      <input
        className={`form-control row rounded-lg border-0 ${className}`}
        placeholder={label}
        type={type}
        name={name}
        defaultValue={value}
        {...props}
      />
      {helperText &&(
        <span className={"form-text text-muted pl-1"}>{helperText}</span>
      )}
    </div>
  )
}

export default TextFieldRow;
