import React, {memo, useEffect, useState} from "react";
import {
  CustomDialog,
  TextFieldRow,
  CustomSelect,
  FormSuccessMessage,
  FormErrorMessage
} from "~components";
import SVG from "react-inlinesvg";
import {Tooltip} from "@material-ui/core";
import { useUser, useRole } from "~store";

const initUser = {
  id: "",
  name: "",
  email: "",
  role: "",
  password: "",
  password_confirmation: "",
};

const UserEditModal = ({ row = null}) => {

  const [state, actions] = useUser();
  const [roleState, ] = useRole();
  const [triggerClose, setTriggerClose] = useState(false);
  const [user, setUser] = useState(JSON.parse(JSON.stringify(initUser)));
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      handleReset();
    }
  }, [])

  useEffect(() => {
    if(row) {
      setUser({
        ...initUser,
        ...row,
        role: row.roles[0]
      })
    }
  }, [row]);

  const handleReset = () => {
    setUser(JSON.parse(JSON.stringify(initUser)));
    setSuccess(false);
  }

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = async () => {
    let result = await actions.saveUser(user);

    if(result) {
      setSuccess(true);
      setTimeout(() => {
        actions.setState({ errors: false })
        setSuccess(false);
        setTriggerClose(true);
      }, 1000)
    }
  }

  return(
    <>
      <CustomDialog
        title={`Create new user`}
        maxWidth={`sm`}
        triggerCloseButton={triggerClose}
        triggerButton={user && user.id
          ? (
            <Tooltip arrow title={"Edit"}>
              <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2">
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={"/media/svg/icons/Communication/Write.svg"} />
                </span>
              </span>
            </Tooltip>
          ) : (
            <button className={`btn btn-primary`}>
              Create
            </button>
        )}
        actionButtons={<button className={`btn btn-primary`} onClick={handleSubmit}>
          Save
        </button>}
      >
        <>
          {success && <FormSuccessMessage message={`User successfully saved.`}/>}
          {state.errors && <FormErrorMessage errors={state.errors} />}
          <div className={`custom-form mt-4 px-5`}>
            <TextFieldRow
              label={`Name`}
              name={`name`}
              value={user['name']}
              onChange={(event) => handleChange(event)}
            />
            <TextFieldRow
              label={`Email`}
              name={`email`}
              value={user['email']}
              onChange={(event) => handleChange(event)}
            />
            <CustomSelect
              name={ "role"}
              label={"User Role"}
              value={user.role}
              options={roleState.roles}
              optionLabelKey={`name`}
              optionValueKey={`name`}
              onChange={(event) => handleChange(event)}
            />
            <TextFieldRow
              autoComplete="new-password"
              type={`password`}
              label={`Password`}
              name={`password`}
              value={user['password']}
              onChange={(event) => handleChange(event)}
            />
            <TextFieldRow
              autoComplete="new-password"
              type={`password`}
              label={`Password Confirmation`}
              name={`password_confirmation`}
              value={user['password_confirmation']}
              onChange={(event) => handleChange(event)}
            />
          </div>
        </>
      </CustomDialog>
    </>
  )
}

export default memo(UserEditModal);
