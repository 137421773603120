import React, {memo, useEffect, useState} from "react";
import {TextFieldRow, FormSuccessMessage,
  CustomCKEditor, CustomSelect, FormErrorMessage
} from "~components";
import { useJob, useCompany, useUser } from "~store";


const JobForm = ({ id, job, setJob, success}) => {

  const user = localStorage.user ? JSON.parse(localStorage.user) : {};
  const [state, actions] = useJob();
  const [companyState, companyActions] = useCompany();
  const [userState, userActions] = useUser();
  const [recuiterUuid, setRecruiterUuid] = useState("");

  useEffect(() => {
    if(user.roles.includes('admin')) {
      userActions.fetchRecruiters();
    }
  }, [])

  useEffect(() => {
    companyActions.fetchAll(recuiterUuid);
  }, [recuiterUuid]);

  useEffect(() => {
    if(id) {
      fetchJob()
    }
  }, [id]);

  const fetchJob = async () => {
    let result = await actions.fetchJob(id);
    setJob({...job, ...result});
    setRecruiterUuid(result.company.recruiter_uuid);
  }

  const handleChange = (key, value) => {
    setJob({ ...job, [key]: value});
    if(Object.keys(state.errors).length > 0) {
      actions.setState({ errors: [] });
    }
  }

	return(
    <>
			{success && <FormSuccessMessage message={`Job successfully saved.`}/>}
			{Object.keys(state.errors).length > 0 && <FormErrorMessage errors={state.errors} />}
			<div id={"job-form"} className={`custom-form mt-4 px-5`}>
				{(user.hasOwnProperty('roles') && user.roles.includes('admin') && (
					<CustomSelect
						value={recuiterUuid}
						label={`Recruiter`}
						optionValueKey={`recruiter_uuid`}
						optionLabelKey={`name`}
						options={userState.recruiters}
						onChange={(event) => setRecruiterUuid(event.target.value)}
						preOptions={<option></option>}
					/>
				))}
				<CustomSelect
					value={`${job.company_id}`}
					label={`Company`}
					optionValueKey={`id`}
					optionLabelKey={`name`}
					options={companyState.allCompanies}
					onChange={(event) => handleChange("company_id", event.target.value)}
					preOptions={<option></option>}
				/>
				<TextFieldRow
					label={`Position`}
					name={`position`}
					value={job.position}
					onChange={(event) => handleChange("position", event.target.value)}
				/>
				<div className="form-group mb-5">
					<label className={`font-weight-bolder`}>
						Description
					</label>
					<CustomCKEditor
						data={job.description}
						handleOnChange={(data) => handleChange("description", data)}
						timeOut={0}
					/>
				</div>
			</div>
		</>
  )
}

export default memo(JobForm);
