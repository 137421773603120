import React, {memo, useState} from "react";
import { CustomDialog } from "~components";
import SVG from "react-inlinesvg";
import JobForm from "./JobForm";
import { Tooltip, Typography, CircularProgress, Button } from "@material-ui/core";
import { useJob } from "~store";
import $ from "jquery";

const initJob = {
  id: "",
  position: "",
  description: "",
};

const JobEdit = ({ id = null}) => {

  const [, actions] = useJob();
  const [success, setSuccess] = useState(false);
  const [triggerClose, setTriggerClose] = useState(false);
  const [job, setJob] = useState(JSON.parse(JSON.stringify(initJob)));
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    setIsSaving(true);
    let result = await actions.saveJob(job);

    if(result) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setTriggerClose(true);
      }, 3000)
    }
    setIsSaving(false);
  }

  const resetJob = () => {
    setJob(JSON.parse(JSON.stringify(initJob)))
  }

  const handleOnExit = () => {
    if(job.id === "") {
      resetJob();
    }
  }

  return(
    <CustomDialog
      title={id ? `Edit job` : `Create new job`}
      maxWidth={`md`}
      onExit={handleOnExit}
      triggerCloseButton={triggerClose}
      triggerButton={
        id ? (
          <Tooltip arrow title={"Edit"}>
            <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={"/media/svg/icons/Communication/Write.svg"} />
              </span>
            </span>
          </Tooltip>
        ) : (
          <Typography variant={"body1"} component={"span"} className={`btn btn-primary bg-green text-white py-3 px-8`}>
            Create
          </Typography>
      )}
      actionButtons={
        <Button className={`btn btn-rh-green w-120px text-white`}
          onClick={handleSubmit}
          disabled={job.name === ""}
        >
          {!isSaving && <span>Save</span>}
          {isSaving && (
            <span>
              <CircularProgress size={16} color={"inherit"} />
              <span className="mx-1">Saving ...</span>
            </span>
          )}
        </Button>
      }
    >
      <JobForm
        id={id}
        job={job}
        setJob={setJob}
        success={success}
      />
    </CustomDialog>
  )
}

export default memo(JobEdit);
