import React from "react";
import moment from "moment";

const DateJoinedColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <span className="text-muted font-weight-bold">
      {row.created_at ? moment(row.created_at).format("MM/DD/YYYY") : ""}
    </span>
  )
}

export default DateJoinedColumnFormatter;
