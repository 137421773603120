import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  CompanyColumnFormatter,
  DateCreatedColumnFormatter
} from "~pages/Jobs/components/column-formatters";
import globalHook from 'use-global-hook';

let recruiterColumn =  {
  dataField: "company.recruiter.name",
  text: "Recruiter",
  sort: true,
  sortCaret: sortCaret,
  headerSortingClasses,
  headerClasses: ""
};

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3 w-55px"
  },
  {
    dataField: "company_id",
    text: "Company",
    formatter: CompanyColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "position",
    text: "Position",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "created_at",
    text: "Date Created",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3 align-baseline",
    style: { minWidth: "125px" },
  },
];

const getColumns = () => {
  let tableColumns = columns;
  if(localStorage.user && JSON.parse(localStorage.user).roles.includes('admin')) {
    tableColumns = [];
    for(let i in columns) {
      tableColumns.push(columns[i]);
      if(i == 0) {
        tableColumns.push(recruiterColumn);
      }
    }
  }
  return tableColumns;
}

const initialState = {
  columns: getColumns(),
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
    status: "succeeded",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchJobsByCompany: async (store, company_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-job-listings?company_id=${company_id}`
      });
      return data.data;
    }
    catch ({ response }) {
      console.log(response);
      return false;
    }
  }
};

const useJobList = globalHook(React, state, actions);

export default useJobList;
