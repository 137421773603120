import React, {Fragment, memo, useState} from "react";
import SVG from "react-inlinesvg";
import {Button, Tooltip} from "@material-ui/core";
import { CustomDialog } from "~components";
import { useUser } from "~store";

const UserDeleteModal = ({ row }) => {

  const [, userActions] = useUser();
  const [triggerClose, setTriggerClose] = useState(false);
  const [message, setMessage] = useState("");

  const deleteUser = async (id) => {
    setMessage("Deleting ...");
    await userActions.deleteUser(id);
    setMessage("Successfully deleted.");

    setTimeout(
      function() {
        setTriggerClose(true);
      }, 6000
    );
    setTimeout(
      function() {
        setTriggerClose(false);
      }, 6500
    );
  }

  return(
    <CustomDialog
      title={""}
      dividers={false}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Delete"}>
          <button className="btn btn-icon btn-light btn-hover-danger btn-sm">
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={"/media/svg/icons/General/Trash.svg"} />
            </span>
          </button>
        </Tooltip>
      }
      actionButtons={
        <Button className={"btn btn-danger btn-elevate"} onClick={() => deleteUser(row.id)}>
          Yes
        </Button>
      }
    >
      <div className={"font-size-h3 w-100 text-center py-10"}>
        {!message && (
          <Fragment>
            Are you sure you want to delete this user (<span className={"font-weight-bold"}>
            { row.fname && row.lname ? `${row.fname} ${row.lname}` : row.email}
            </span>)?
          </Fragment>
        )}
        {message && message}
      </div>
    </CustomDialog>
  )
}

export default memo(UserDeleteModal);
