import React, { memo } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const CopiedPopover = ({triggerButton, onClickFunction}) =>  {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    onClickFunction();
    setAnchorEl(event.currentTarget);

    setTimeout(function() {
        setAnchorEl(event.currentTarget);
    }, 900);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <span>
        <span onClick={handleClick}>
            {triggerButton}
        </span>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
        >
            <Typography variant='body2' className='px-2 py-1 shadow-sm'>Copied!</Typography>
        </Popover>
    </span>
  );
}
export default memo(CopiedPopover);