import React from "react";

const CompanyColumnFormatter = (cellContent, row) => {
  return (
    <div>
      {row.company.name}
    </div>
  );
}

export default CompanyColumnFormatter;
