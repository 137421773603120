import React from 'react';
import globalHook from 'use-global-hook';

const state = {
  loading: false,
  comments: [],
  openTicketsCount: "",
  hideClosedTickets: true,
  expanded: {}
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchComments: async (store, application_id) => {
    store.setState({ loading: true });
    try {
      let params = `?application_id=${application_id}
        &status=${store.state.hideClosedTickets ? "open" : ""}`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/comment${params}`
      });
      store.setState({
        comments: data.data,
        loading: false,
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  saveComment: async (store, comment) => {
    try {
      let config = {headers: { "Content-Type": "application/json" }};
      let url = `/api/comment`;

      if(comment.parent_id === "") {
        comment.status = "open";
      }

      await global.axios.post(url, comment, config);
      await store.actions.fetchComments(comment.application_id);

      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  countOpenTickets: async (store, application_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/count-open-tickets?application_id=${application_id}`
      });
      store.setState({ openTicketsCount: data });
    }
    catch({ response }) {
        console.log(response);
    }
  },
  closeTicket: async (store, comment_id) => {
    try {
      await global.axios({
        method: "POST",
        data: {_method: "PUT"},
        url: `/api/close-ticket/${comment_id}`
      });
      return true;
    }
    catch({ response }) {
      console.log(response);
    }
  },
};

const useComment = globalHook(React, state, actions);

export default useComment;
