import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  LogoColumnFormatter,
  DateCreatedColumnFormatter
} from "~pages/Companies/components/column-formatters";
import globalHook from 'use-global-hook';

let recruiterColumn =  {
  dataField: "recruiter.name",
  text: "Recruiter",
  sort: true,
  sortCaret: sortCaret,
  headerSortingClasses,
  headerClasses: ""
};

let columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-55px"
  },
  {
    dataField: "logo",
    text: "",
    formatter: LogoColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-90px"
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    classes: "font-weight-bold",
    headerSortingClasses,
  },
  {
    dataField: "location",
    text: "Location",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "tagline",
    text: "Tagline",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "created_at",
    text: "Date Created",
    sort: true,
    formatter: DateCreatedColumnFormatter,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px text-center"
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-2",
    headerClasses: "text-right w-100px",
  },
];

const getColumns = () => {
  let tableColumns = columns;
  if(localStorage.user && JSON.parse(localStorage.user).roles.includes('admin')) {
    tableColumns = [];
    for(let i in columns) {
      tableColumns.push(columns[i]);
      if(i == 0) {
        tableColumns.push(recruiterColumn);
      }
    }
  }
  return tableColumns;
}

const initialState = {
  columns: getColumns(),
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
    status: "succeeded",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
};

const useCompanyList = globalHook(React, state, actions);

export default useCompanyList;
