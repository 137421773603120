import React, {useState} from "react";
import SVG from "react-inlinesvg";
import {NavLink} from "react-router-dom";
import {Button, Tooltip} from "@material-ui/core";
import {Visibility} from "@material-ui/icons";
import { CustomDialog } from "~components";
import JobEdit from "../JobEdit";
import CopiedPopover from "./CopiedPopover";
import { useJob } from "~store";

const ActionsColumnFormatter = (cellContent, row) => {
  return (
    <div>
      <CopyApplicationFormUrl row={row} />
      <CopyUrl row={row} />
      <ViewJobPage row={row} />
      <JobEdit id={row.id} />
      <DeleteButton row={row} />
    </div>
  );
}

const ViewJobPage = ({ row }) => {
  return(
    <Tooltip arrow title={"View Job Page"}>
      <NavLink to={`/job-information/${row.id}`} target={"_blank"} className="btn btn-icon btn-light btn-sm mx-2">
        <Visibility color={"primary"} />
      </NavLink>
    </Tooltip>
  )
}

const CopyUrl = ({ row }) => {
  const handleOnClick = () => {
    let url = `${window.location.origin}/job-information/${row.id}`;
    window.navigator.clipboard.writeText(url);
  }
  return(
    <CopiedPopover
      onClickFunction={handleOnClick}
      triggerButton={
        <Tooltip arrow title={"Copy Job Url"}>
          <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={"/media/svg/icons/General/Clipboard.svg"} />
            </span>
          </span>
        </Tooltip>
      }
    />    
  )
}

const CopyApplicationFormUrl = ({ row }) => {
  const handleOnClick = () => {
    let url = `${window.location.origin}/application-form?j=${row.id}&c=${row.company.id}&r=${row.company.recruiter_uuid}`;
    window.navigator.clipboard.writeText(url);
  }
  return(
    <CopiedPopover
      onClickFunction={handleOnClick}
      triggerButton={
        <Tooltip arrow title={"Copy Application Form Job Url"}>
      <span className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2">
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={"/media/svg/icons/Files/File.svg"} />
        </span>
      </span>
    </Tooltip>
      }
    />    
  )
}

const DeleteButton = ({ row }) => {

  const [, actions] = useJob();
  const [triggerClose, setTriggerClose] = useState(false);
  const [message, setMessage] = useState("");

  const deleteJob = async (id) => {
    setMessage("Deleting ...");
    await actions.deleteJob(id);
    setMessage("Successfully deleted.");

    setTimeout(
      function() {
        setTriggerClose(true);
      }, 1000
    );
    setTimeout(
    function() {
      setTriggerClose(false);
      }, 1500
    );
  }

  return(
    <CustomDialog
      title={""}
      dividers={false}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Delete"}>
          <button className="btn btn-icon btn-light btn-hover-danger btn-sm">
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={"/media/svg/icons/General/Trash.svg"} />
            </span>
          </button>
        </Tooltip>
      }
      actionButtons={
        <Button className={"btn btn-danger btn-elevate"} onClick={() => deleteJob(row.id)}>
          Yes
        </Button>
      }
    >
      <div className={"font-size-h3 w-100 text-center py-10"}>
        {!message && (<>Are you sure you want to delete this job?</>)}
        {message && message}
      </div>
    </CustomDialog>
  )
}

export default ActionsColumnFormatter;
