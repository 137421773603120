import React from "react";
import globalHook from 'use-global-hook';
import axios from "axios";

const initialState = {
    data: {},
    options: {
        "job-title": []
    }
};

const actions = {
    setState: (store, newState) => {
        store.setState(newState)
    },
    fetchOptions: async (store, param = 'resume') => {
        try {
            let { data } = await axios.get(`/api/fetch-pre-written-texts`);
            let fieldOptions = {};
            let newData = {};

            for(let item of data.data) {
                newData[item['slug']] = item['options'];

                if(item['slug'] === "pre-written-responsibilities") {
                    continue;
                }

                let options = [];
                for(let sItem of item['options']) {
                    options.push(sItem.option)
                }

                fieldOptions[item['slug']] = options;

                if(item.slug === "job-title") {
                    fieldOptions[item.slug] = fieldOptions[item.slug].sort((a,b)=> (a.job_title > b.job_title ? 1 : -1))
                }
            }

            store.setState({
                options: fieldOptions,
                data: newData
            });
        }
        catch(error) {
            console.log(error);
        }
    },
    setJobResponsibilities: (store, jobTitle) => {
        let jobResponsibilities = [];

        if(jobTitle !== ""
            && Object.keys(store.state.data).length > 0
            && store.state.data.hasOwnProperty('job-title')
            && store.state.data['job-title'].length > 0
        ){
            let job = store.state.data['job-title'].filter(item => item.option === jobTitle);

            if(job.length > 0) {
                job = job[0];
                if(job.hasOwnProperty('children')) {
                  for (let item of job.children) {
                    jobResponsibilities.push(item.option);
                  }
                }
            }
        }

        store.setState({
            options: {
                ...store.state.options,
                "pre-written-responsibilities": jobResponsibilities
            }
        });
    }
}

const useOption = globalHook(React, initialState, actions)

export default useOption;
