import React, { Suspense } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "~layout";
import NotFound from "~app/pages/NotFound";
import { useRoute, useAuth } from "~store";

export function Routes() {

  const location = useLocation();
  const [routeState, routeActions] = useRoute();
  const [, authActions] = useAuth();

  if(routeActions.isFound(location.pathname)) {
    if(!authActions.isLoggedIn() && routeActions.isPrivate(location.pathname)) {
      return(<Redirect to={"/login"} />)
    }
    if(authActions.isLoggedIn() && location.pathname === "/login") {
      return(<Redirect to={"/dashboard"} />)
    }
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {routeState.routes.map((route, i) => {
          if(route.hasOwnProperty('redirect')) {
            return (
              <Route
                key={i}
                exact
                path={route.path}
                render={() => (<Redirect to={route.redirect} />)}
              />
            )
          }
          return(
            <ContentRoute
              key={i}
              path={route.path}
              component={route.component}
              exact={route.exact}
              private={route.private}
            />
          )
        })}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
