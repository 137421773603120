import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  totalUsers: 0,
  users: [],
  lastParams: "",
  errors: null,
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchUser: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/user/${id}`
      });
      store.actions.setUser(data.data);
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchUsers: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(queryParams || filters) {
        params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField}
        &sortOrder=${queryParams.sortOrder}
        &searchText=${filters.searchText}`;
      } else {
        params = store.state.lastParams;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/user${params}`
      });

      store.setState({
        users: data.data,
        totalUsers: data.meta.total,
        loading: false,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  saveUser: async (store, user) => {
    store.setState({ errors: null });
    try {
      let url = user.id !== "" ? `/api/user/${user.id}` : `/api/user`;

      if(user.id) {
        user['_method'] = "PUT";
      }

      await global.axios.post(url, user);
      await store.actions.fetchUsers();

      return true;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({ errors: response.data.errors })
        return false;
      }
    }
  },
  deleteUser: async (store, user_id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/user/${user_id}`
      });
      await store.actions.fetchUsers();
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useUser = globalHook(React, state, actions);

export default useUser;
