import React  from "react";
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0.85rem !important",
      backgroundColor: "#f1f0f0 !important",
      borderColor: "#f1f0f0 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 !important",
    },
  }
}));

const CustomSelect = ({
  label = null,
  options = [], // [{label: label, value: value}, ...]
  optionLabelKey = "label",
  optionValueKey = "value",
  wrapperClass = "",
  labelClass = "",
  className = "",
  preOptions = "",
  ...props
}) => {
  const classes = useStyles();
  return(
    <div className={`form-group row custom-select-field ${wrapperClass} ${classes.root}`}>
      {label && (
        <label className={`pl-1 ${labelClass}`}>
          {label}
        </label>
      )}
      <TextField
        className={`mt-0 ${className}`}
        select
        fullWidth
        label=""
        variant="outlined"
        margin={"dense"}
        SelectProps={{ native: true }}
        {...props}
      >
        {preOptions}
        {options.map((option) => (
          <option key={option[optionValueKey]} value={option[optionValueKey]} className={`text-capitalize`}>
            {option[optionLabelKey]}
          </option>
        ))}
      </TextField>
    </div>
  )
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  optionLabelKey: PropTypes.string,
  optionValueKey: PropTypes.string,
  handleChange: PropTypes.func
};

export default CustomSelect;
