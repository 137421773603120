import React from 'react';
import globalHook from 'use-global-hook';

const state = {
  jobs: [],
  listCount: 0,
  loading: false,
  lastParams: '',
  errors: {},
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchJob: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/job-listing/${id}`
      });
      return data.data;
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchJobs: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });
    try {
      let params = "";

      if(queryParams && filters) {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField.replace(".", "->")}
          &sortOrder=${queryParams.sortOrder}
          &searchText=${filters.searchText}`;
      } else {
        params = store.state.lastParams;
      }

      store.setState({lastParams : params });

      let { data } = await global.axios({
        method: "GET",
        url: `/api/job-listing${params}`
      });
      store.setState({
        jobs: data.data,
        listCount: data.meta.total,
        loading: false,
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  saveJob: async (store, job) => {

    store.setState({ errors: [] })

    try {
      let url = `/api/job-listing`;

      if(job.id) {
        url += `/${job.id}`;
        job["_method"] = "PUT";
      }

      let { data } = await global.axios.post(url, job);
      store.actions.fetchJobs();

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({ errors: response.data.errors })
      }
      return false;
    }
  },
  deleteJob: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/job-listing/${id}`
      });
      store.actions.fetchJobs();
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchJobsByCompany: async (store, company_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-jobs?company_id=${company_id}`
      });
      return data.data;
    }
    catch ({ response }) {
      console.log(response);
    }
  }
};

const useJob = globalHook(React, state, actions);

export default useJob;
