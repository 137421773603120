/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "~layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "~helpers";
import { DropdownTopbarItemToggler } from "~partials/dropdowns";
import { useAuth } from "~store";

export function UserProfileDropdown() {

  const history = useHistory();
  const [authState, authActions] = useAuth();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {authState.user.name}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            {/*{!authState.user.hasOwnProperty('avatar') || (authState.user.hasOwnProperty('avatar') && authState.user.avatar === "") && (*/}
              <span className="symbol-label font-size-h5 font-weight-bold text-capitalize">
                {authState.user.email.charAt(0)}
              </span>
            {/*)}*/}
            {/*{authState.user.hasOwnProperty('avatar') && authState.user.avatar !== "" && (*/}
            {/*  <img alt="Pic" className="hidden" src={authState.user.avatar} />*/}
            {/*)}*/}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {/*{authState.user.fname} {authState.user.lname}*/}
                </div>
                {/*<span className="label label-light-success label-lg font-weight-bold label-inline">*/}
                {/*  3 messages*/}
                {/*</span>*/}
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                {/*{authState.user.avatar === "" && (*/}
                {/*  <span className="symbol-label text-success font-weight-bold font-size-h4">*/}
                {/*    {authState.user.fname[0]}*/}
                {/*  </span>*/}
                {/*)}*/}
                {/*{authState.user.avatar !== "" && (*/}
                {/*  <img alt="Pic" className="hidden" src={authState.user.avatar} />*/}
                {/*)}*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {/*{authState.user.fname} {authState.user.lname}*/}
              </div>
              {/*<span className="label label-success label-lg font-weight-bold label-inline">*/}
              {/*  3 messages*/}
              {/*</span>*/}
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/my-profile/personal-information" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  {/*<span className="label label-light-danger label-inline font-weight-bold">*/}
                  {/*  update*/}
                  {/*</span>*/}
                </div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer justify-content-end px-8 py-5">
            <button
              onClick={() => authActions.logout(history)}
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
