import React from "react";
import moment from "moment";

const DateCreatedColumnFormatter = (cellContent, row) => {
  return (
    <div className="w-100 font-weight-bold text-center">
      {row.created_at ? moment(row.created_at).format("MM/DD/YYYY") : ""}
    </div>
  )
}

export default DateCreatedColumnFormatter;
