import React from 'react';
import globalHook from 'use-global-hook';

export const initialHistory = {
  "job-title": "",
  company: "",
  "company-description": "",
  date_start: "",
  date_end: "",
  responsibility: "",
  achievement: "",
  show: true,
};

const initialEducation = {
  institution: "",
  qualification: "",
  completion: "",
  city: "",
  description: "",
  show: true,
};

const initialReference = {
  firstname: "",
  lastname: "",
  title: "",
  company: "",
  phone: "",
  email: ""
};

const application = {
  id: "",
  company_id: 1,
  status: "For Review",
  resume_title: "",
  "job-title": "",
  "first-name": "",
  "last-name": "",
  email: "",
  contact: "",
  "linked-in": "",
  "place-birth": "",
  "video-url": "",
  show_summary: true,
  "professional-summary": "",
  history: [JSON.parse(JSON.stringify(initialHistory))],
  show_education: true,
  education: [JSON.parse(JSON.stringify(initialEducation))],
  "professional-skills": [],
  "technical-skills": [],
  show_technical_skills: true,
  "personal-attributes": [],
  languages: [],
  show_lang: true,
  reference_upon_request: false,
  referrence: [JSON.parse(JSON.stringify(initialReference))],
  show_career: true,
  "career-highlights": "",
  show_publications: true,
  show_conference: true,
  "conferences-attended": "",
  publications: "",
  show_volunteer: true,
  volumnteer: "",
  show_last: true,
  lastly: "",
};

const state = {
  application: JSON.parse(JSON.stringify(application)),
  applications: [],
  listCount: 0,
  loading: false,
  filterOptions: {
    companies: [],
    positions: [],
  },
  lastParams: '',
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  setApplication: (store, key, value) => {
    store.setState({
      application: {
        ...store.state.application,
        [key]: value
      }
    });
  },
  addApplicationHistory: (store) => {
    let history = store.state.application.history;
    let newHistory = JSON.parse(JSON.stringify(initialHistory));
    history.push(newHistory);
    store.actions.setApplication("history", history)
  },
  addApplicationEducation: (store) => {
    let education = store.state.application.education;
    let newEducation = JSON.parse(JSON.stringify(initialEducation));
    education.push(newEducation);
    store.actions.setApplication("education", education);
  },
  addApplicationReference: (store) => {
    let reference = store.state.application.referrence;
    let newReference = JSON.parse(JSON.stringify(initialReference));
    reference.push(newReference);
    store.actions.setApplication("education", reference);
  },
  resetApplication: (store) => {
    store.setState({application: JSON.parse(JSON.stringify(application))});
  },
  fetchApplication: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/application/${id}`
      });

      // set null values to empty string
      let application =  {
        ...data.data,
        id: data.id,
        status: data.status,
        job_listing_id: data.job_listings.length > 0 ? data.job_listings[0].id : "",
        company_id: data.job_listings.length > 0 ? data.job_listings[0].company.id : "",
        recruiter_uuid: data.job_listings.length > 0 ? data.job_listings[0].company.recruiter_uuid : "",
      };

      for(let key of Object.keys(application)) {
        application[key] = application[key] ? application[key] : "";

        if(Array.isArray(application[key])) {
          for(let i in application[key]) {
            if(!application[key][i]) {
              application[key][i] = {};
            } else {
              for(let k2 of Object.keys(application[key][i])) {
                application[key][i][k2] = application[key][i][k2] ? application[key][i][k2] : "";
              }
            }
          }
        }
      }
      store.setState({ application: application });
      return application;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  },
  fetchApplications: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });
    try {
      let params = "";

      if(queryParams && filters) {
        params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField.replace(".", "->")}
        &sortOrder=${queryParams.sortOrder}
        &searchText=${filters.searchText}
        &company=${filters.company}
        &position=${filters.position}`;
      } else {
        params = store.state.lastParams;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/application${params}`
      });

      store.setState({
        applications: data.data,
        listCount: data.meta.total,
        loading: false,
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  saveApplication: async (store, genState, redirect=true) => {
    try {
      let config = {headers: { "Content-Type": "application/json" }};
      let payload = {...genState, ...store.state.application};
      let url = `/api/application`;

      if(payload.id !== "") {
        url += `/${payload.id}`;
        payload["_method"] = "PUT";
      } else {
        payload.status = "For Review";
      }

      let { data } = await global.axios.post(url, payload, config);
      localStorage.setItem("isDoneSaving", true);

      if(redirect && payload.id === "") {
        window.parent.location.href = `/applications/application-editor/${data.id}`;
      }

      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  deleteApplication: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/application/${id}`
      });
      store.actions.fetchApplications();
      return true;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  }
};

const useApplication = globalHook(React, state, actions);

export default useApplication;
