import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from "@material-ui/core";

const CustomTextField = ({
  label = "",
  labelClass = "text-muted",
  type = "text",
  margin = "dense",
  // defaultValue = "",
  required = false,
  error = false,
  className = "",
  ...props
 }) => {
  return(
    <>
      {label && (
        <label className={`form-text ${labelClass}`}>
          {label}
        </label>
      )}
      <TextField
        fullWidth
        label=""
        margin={margin}
        variant="outlined"
        type={type}
        error={error}
        required={required}
        // defaultValue={defaultValue}
        className={`form-control-solid custom-form-input ${className}`}
        {...props}
      />
    </>
  )
}

CustomTextField.propTypes = {
  error: PropTypes.bool,
  required: PropTypes.bool,
  // defaultValue: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  type: PropTypes.string
};

export default CustomTextField;
