import React from "react";

const UserTypeColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span className="text-muted font-weight-bold text-capitalize">
      {row.roles[0]}
    </span>
  )
}

export default UserTypeColumnFormatter;
