import React from "react";

const SplashScreen = () => {
  return(
    <div className="kt-splash-screen w-100 position-absolute top-0 left-0 h-100 d-flex flex-center flex-column align-items-center"
      style={{ zIndex: 1000, backgroundColor: "#f2f3f8",}}
    >
      <img src="/media/logos/rh-logo-header.png" alt="Recruitable Hub logo" />
      <svg className="splash-spinner mt-8" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  )
}
export default SplashScreen;
